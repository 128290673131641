import React, { useEffect, useLayoutEffect } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap-extended/lib/bootstrap-extended.css";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "./Components/style.css";
import "./Components/Footer.css";
import "./Components/Info_Section.css";
import "./Components/Input_fields.css";
import "./Components/Button.css";
import "./App.css";
import $ from "jquery";

import { Route, Routes, useLocation } from "react-router-dom";
import NoInternet from "./Components/NoInternet";

// import RedemptionForm from "./Components/RedemptionForm";
// import UserForm from "./Components/UserForm";
// import Thankyou from "./Components/Thankyou";
// import CertDetails from "./Components/CertDetails";

// import AccountantIndex from "./Components/admin/Accountant_Index/AccountantIndex";
// import AddAccountant from "./Components/admin/AdminMain/AddAccountant";
// import AddAdmin from "./Components/admin/AdminMain/AddAdmin";
// import AddByCompanies from "./Components/admin/AdminMain/AddByCompanies";
// import AddByJob from "./Components/admin/AdminMain/AddByJob";
// import AddCard from "./Components/admin/AdminMain/AddCard";
// import AddCutomer from "./Components/admin/AdminMain/AddCutomer";
// import AddOsr from "./Components/admin/AdminMain/AddOsr";
// import AddPavlina from "./Components/admin/AdminMain/AddPavlina";
// import AddRep from "./Components/admin/AdminMain/AddRep";
// import AddSitePlastic from "./Components/admin/AdminMain/AddSitePlastic";
// import AddState from "./Components/admin/AdminMain/AddState";
// import AddSubCompany from "./Components/admin/AdminMain/AddSubCompany";
// import CertiDetails from "./Components/admin/AdminMain/CertiDetails";
// import CertRecords from "./Components/admin/AdminMain/CertRecords";
// import Dashboard from "./Components/admin/AdminMain/Dashboard";
// import DelByUpload from "./Components/admin/AdminMain/DelByUpload";
// import EditJob from "./Components/admin/AdminMain/EditJob";
// import EditLockup from "./Components/admin/AdminMain/EditLockup";
// import ExportSendMail from "./Components/admin/AdminMain/ExportSendMail";
// import ManageAccountant from "./Components/admin/AdminMain/ManageAccountant";
// import ManageAdmin from "./Components/admin/AdminMain/ManageAdmin";
// import ManageByCompanies from "./Components/admin/AdminMain/ManageByCompanies";
// import ManageByJob from "./Components/admin/AdminMain/ManageByJob";
// import ManageCard from "./Components/admin/AdminMain/ManageCard";
// import ManageCustomer from "./Components/admin/AdminMain/ManageCustomer";
// import ManageJob from "./Components/admin/AdminMain/ManageJob";
// import ManageLockup from "./Components/admin/AdminMain/ManageLockup";
// import ManageMails from "./Components/admin/AdminMain/ManageMails";
// import ManageOsr from "./Components/admin/AdminMain/ManageOsr";
// import ManagePavlina from "./Components/admin/AdminMain/ManagePavlina";
// import ManageRep from "./Components/admin/AdminMain/ManageRep";
// import ManageSitePlastic from "./Components/admin/AdminMain/ManageSitePlastic";
// import ManageState from "./Components/admin/AdminMain/ManageState";
// import OldFile from "./Components/admin/AdminMain/OldFile";
// import RandomCert from "./Components/admin/AdminMain/RandomCert";
// import SendMail from "./Components/admin/AdminMain/SendMail";
// import SubCompany from "./Components/admin/AdminMain/SubCompany";
// import ViewCertificateDetail from "./Components/admin/AdminMain/ViewCertificateDetail";
// import AddCustomer from "./Components/admin/Customer_panel/AddCustomer";
// import Header from "./Components/admin/includes/Header";

// import Login from "./Components/admin/login/Login";
// import OsrIndex from "./Components/admin/Osr_panel/OsrIndex";

// import ViewOsrHistroy from "./Components/admin/Osr_panel/ViewOsrHistroy";
// import CardToMailed from "./Components/admin/Pavlina_panel/CardToMailed";
// import MailMerge from "./Components/admin/Pavlina_panel/MailMerge";
// import PavlinaIndex from "./Components/admin/Pavlina_panel/PavlinaIndex";
// import SearchMplJob from "./Components/admin/Pavlina_panel/SearchMplJob";
// import VirtualCard from "./Components/admin/Pavlina_panel/VirtualCard";
// import { useDispatch, useSelector } from "react-redux";

// import jwtDecode from "jwt-decode";
// import { bindActionCreators } from "redux";
// import { actionCreaters } from "./Redux";

// import EditCustomer from "./Components/admin/AdminMain/EditCustomer";
// import EditSitePlastic from "./Components/admin/AdminMain/EditSitePlastic";
// import EditOsr from "./Components/admin/AdminMain/EditOsr";
// import EditSubCompany from "./Components/admin/AdminMain/EditSubCompany";
// import EditAccountant from "./Components/admin/AdminMain/EditAccountant";
// import EditRep from "./Components/admin/AdminMain/EditRep";
// import EditPavlina from "./Components/admin/AdminMain/EditPavlina";
// import EditAdmin from "./Components/admin/AdminMain/EditAdmin";
// import EditState from "./Components/admin/AdminMain/EditState";
// import EditCard from "./Components/admin/AdminMain/EditCard";
// import AddJob from "./Components/admin/AdminMain/AddJob";
// import ManageJobDetail from "./Components/admin/AdminMain/ManageJobDetail";
import HeaderUser from "./Components/HeaderUser";

import axios from "axios";

import { ToastContainer } from "react-toastify";

import RegisterForm from "./Components/RegisterForm";
import TestOurTech_thankyou from "./Components/TestOurTech_thankyou";
import PrivacyPolicy from "./Components/PrivacyPolicy";
import Error429 from "./Components/Error429";
import NotFound from "./Components/NotFound";

function App() {
  const locat = useLocation();

  useEffect(() => {
    if (locat.pathname.match(/\/{2,}/)) {
      if (locat.pathname.indexOf("admin/") > -1) {
        window.location.href = "/";
      } else {
        window.location.href = "/";
      }
    }

    const interceptor = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error?.response?.status === 429) {
          window.location.href = "/error-429";
        }

        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, []);

  useLayoutEffect(() => {
    localStorage.setItem("isVoucher", false);
    const backToTop = () => {
      return (
        $("html, body").animate(
          {
            scrollTop: 0,
          },
          600
        ),
        !1
      );
    };
    backToTop();
    if (
      locat.pathname === "/" ||
      locat.pathname === "/privacy-policy" ||
      locat.pathname === "/register/registration-thankyou"
    ) {
      var element = document.getElementById("root");
      element.classList.add("img-bg");
    }
  }, [locat.pathname]);

  return (
    <NoInternet>
      <ToastContainer />

      {locat.pathname === "/" ||
      // locat.pathname === "/register/registration-form/" ||
      locat.pathname === "/privacy-policy" ||
      locat.pathname === "/register/registration-thankyou" ? (
        <HeaderUser />
      ) : null}

      <Routes>
        <Route path="/" element={<RegisterForm />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />

        <Route
          path="/register/registration-thankyou"
          element={<TestOurTech_thankyou />}
        />

        <Route path="/error-429" element={<Error429 />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      {locat.pathname.match(/\/{2,}/) ? (
        <div className="fix-white"></div>
      ) : null}
    </NoInternet>
  );
}

export default App;
